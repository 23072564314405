import gql from "graphql-tag";

export const PAGINATE_JOB_CATEGORY = gql`  
    query jobCategorys($page: Int!, $limit: Int!, $filter: String!) {
        jobCategorys(filter: $filter, page: $page, limit: $limit) {
            pageInfo {
                length
            }
            edges {
                node {
                    _id
                    title
                    updated_at
                }
            }
        }
    }
`;

export const CATEGORYS_COUNT = gql`
  query categorys($page: Int!, $limit: Int!, $filter: String!) {
    jobCategorys(page: $page, filter: $filter, limit: $limit) {
      pageInfo {
        length
      }
    }
  }
`;

export const SINGLE_JOB_CATEGORY = gql`
    query jobCategory ($_id: String!) {
        jobCategory(_id: $_id) {
            _id
            title
            slug
            seo_title
            seo_description
        }
    }
`;
