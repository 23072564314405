import gql from "graphql-tag";

export const PAGINATE_CITY = gql`
    query paginateCity($page: Int!, $limit: Int!, $filter: String!) {
        citys(page: $page, filter: $filter, limit: $limit) {
            edges {
                node {
                    _id
                    name
                }
            }
        }
    }
`;


export const PAGINATE_ADDRESS_CITY = gql`
    query paginateCity($page: Int!, $limit: Int!, $filter: String!) {
        citys(page: $page, filter: $filter, limit: $limit) {
            edges {
                node {
                    _id
                    name
                }
            }
            pageInfo {
                length
            }
        }
    }
`;

export const SINGLE_CITY = gql`
    query city($_id: String!) {
        city(_id: $_id) {
            _id
            name
            title
            description
            slug
            focus_keyword
            seo_title
            seo_description
            image
            image_description
        }
    }
`;
