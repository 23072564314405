import ListCompanyType from "@/store/redux/types/ListCompanyType";

export const fetchListCompany = (payload: any) => ({
  type: ListCompanyType.FETCH_LIST_COMPANY,
  payload
});

export const fetchListCompanySucceed = (payload: any) => ({
  type: ListCompanyType.FETCH_LIST_COMPANY_SUCCEED,
  payload
});

export const fetchListCompanyFailed = () => ({
  type: ListCompanyType.FETCH_LIST_COMPANY_FAILED,
});
