import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {colors, createStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsPopover from "@/components/NotificationsPopover/NotificationsPopover";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import palette from "@/theme/palette";

interface NotificationsTypes {
  id: any;
  type: "order" | "user" | "project" | "feature";
  title: string;
  created_at: string;
}

interface TopBarProps {
  className?: string;
  onOpenNavBarMobile?: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none",
    },
    flexGrow: {
      flexGrow: 1,
    },
    notificationsButton: {
      marginLeft: theme.spacing(1),
    },
    notificationsBadge: {
      backgroundColor: colors.orange[600],
    },
    logo: {},
    logoSvg: {
      height: "30px",
      fill: palette.primary.main,
      float: "left",
    },
    logoTitle: {
      padding: "9px 10px",
      float: "left",
      background: palette.primary.light,
      borderRadius: "4px",
      color: "#f0f0f0",
      fontWeight: 900,
      fontSize: "12px",
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      margin: "1px 0 0 8px",
    },
    pointer: {
      cursor: "ponter",
    },
  })
);

const TopBar: React.FunctionComponent<TopBarProps> = (props: TopBarProps) => {
  const {onOpenNavBarMobile, className, ...rest} = props;

  const classes = useStyles();
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState<NotificationsTypes[]>([

  ]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    // setNotifications([{
    //   id: "1",
    //   type: "order",
    //   title: "New order",
    //   created_at: "1 1 2020",
    // }])
  }, []);

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  return (
    <AppBar {...rest} className={classes.root} color="primary">
      <Toolbar>
        <div className={classes.logo}>
          <svg viewBox="0 0 16 16" className={classes.logoSvg}>
            <g>
              <circle className="st0" cx="8" cy="8" r="8"></circle>
              <g>
                <path
                  d="M4.8,12.9c-0.1,0-0.2,0-0.3,0c-0.9-0.2-1.5-1.1-1.4-2c0-0.3,1.2-6.5,7.8-7.8c0.9-0.2,1.8,0.4,2,1.3
 c0.2,0.9-0.4,1.8-1.3,2c-4.4,0.9-5.1,5-5.1,5.1C6.3,12.4,5.6,12.9,4.8,12.9z"
                  style={{fill: "rgb(255, 255, 255)"}}
                ></path>
                <path
                  className="st1"
                  d="M4.8,12.9c-0.1,0-0.2,0-0.3,0c-0.9-0.2-1.5-1.1-1.4-2c0-0.3,1.2-6.5,7.8-7.8c0.9-0.2,1.8,0.4,2,1.3
 c0.2,0.9-0.4,1.8-1.3,2c-4.4,0.9-5.1,5-5.1,5.1C6.3,12.4,5.6,12.9,4.8,12.9z"
                  style={{fill: "rgb(255, 255, 255)"}}
                ></path>
                <path
                  className="st1"
                  d="M3.3,8.1c0.5-1,1.3-2.2,2.5-3.3c0.4-0.4,0.3-1.1-0.2-1.5C5.3,3.1,4.9,3,4.5,3.1C3.7,3.2,3.1,4,3.1,4.9v3.1
 C3.1,8.1,3.2,8.1,3.3,8.1z"
                  style={{fill: "rgb(255, 255, 255)"}}
                ></path>
                <path
                  className="st1"
                  d="M10.7,7.8c-0.2-0.1-0.4-0.2-0.7,0c-0.8,0.4-1.4,1-1.9,1.6c-0.1,0.2-0.1,0.5,0.1,0.7c0.6,0.6,1.1,1,1.3,1.5
 c0.4,1.2,1.2,1.3,1.8,1.3c0.6,0,2-0.8,1.5-2.3C12.3,9.3,11.2,8.3,10.7,7.8z"
                  style={{fill: "rgb(255, 255, 255)"}}
                ></path>
              </g>
            </g>
          </svg>
          <div className={classes.logoTitle}>NHÀ TUYỂN DỤNG</div>
        </div>

        <div className={classes.flexGrow}/>

        <IconButton
          className={classes.notificationsButton}
          color="inherit"
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge badgeContent={notifications.length} color="error">
            <NotificationsIcon/>
          </Badge>
        </IconButton>
        <IconButton color={"inherit"} onClick={() => setOpenDialog(true)}>
          <HomeIcon className={classes.pointer}/>
        </IconButton>
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon/>
          </IconButton>
        </Hidden>
      </Toolbar>

      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{"Về trang chủ"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description">
            Chọn <strong>OK</strong> để quay trở về trang chủ.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenDialog(false)} color="primary">
            Huỷ
          </Button>
          <Button href={process.env.REACT_APP_BASE_URL} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default TopBar;
