import NotificationType from "@/store/redux/types/NotificationType";

export const fetchNotification = (payload: any) => ({
  type: NotificationType.FETCH_NOTIFICATION,
  payload
});

export const fetchNotificationSucceed = (payload: any) => ({
  type: NotificationType.FETCH_NOTIFICATION_SUCCEED,
  payload
});

export const fetchNotificationFailed = () => ({
  type: NotificationType.FETCH_NOTIFICATION_FAILED,
});

export const fetchUnreadNotificationLength = (payload: any) => ({
  type: NotificationType.FETCH_UNREAD_NOTIFICATION_LENGTH,
  payload
});

export const fetchUnreadNotificationLengthSucceed = (payload: any) => ({
  type: NotificationType.FETCH_UNREAD_NOTIFICATION_LENGTH_SUCCEED,
  payload
});

export const fetchUnreadNotificationLengthFailed = () => ({
  type: NotificationType.FETCH_UNREAD_NOTIFICATION_LENGTH_FAILED,
});

export const updateReadNotification = (payload: any) => ({
  type: NotificationType.UPDATE_READ_NOTIFICATION,
  payload
});

export const updateReadNotificationSucceed = () => ({
  type: NotificationType.UPDATE_READ_NOTIFICATION_SUCCEED,
});

export const updateReadNotificationFailed = () => ({
  type: NotificationType.UPDATE_READ_NOTIFICATION_FAILED,
});


export const updateReadAllNotification = (payload: any) => ({
  type: NotificationType.UPDATE_READ_ALL_NOTIFICATION,
  payload
});

export const updateReadAllNotificationSucceed = () => ({
  type: NotificationType.UPDATE_READ_ALL_NOTIFICATION_SUCCEED,
});

export const updateReadAllNotificationFailed = () => ({
  type: NotificationType.UPDATE_READ_ALL_NOTIFICATION_FAILED,
});



