import ListJobPostType from "@/store/redux/types/ListJobPostType";

export const fetchListJobPost = (payload: any) => ({
  type: ListJobPostType.FETCH_LIST_JOB_POST,
  payload
});

export const fetchListJobPostSucceed = (payload: any) => ({
  type: ListJobPostType.FETCH_LIST_JOB_POST_SUCCEED,
  payload
});

export const fetchListJobPostFailed = () => ({
  type: ListJobPostType.FETCH_LIST_JOB_POST_FAILED,
});
