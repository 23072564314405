import gql from "graphql-tag";

export const PAGINATE_COMPANY = gql`
  query companys($page: Int!, $limit: Int!, $filter: String!) {
    companys(page: $page, filter: $filter, limit: $limit) {
      pageInfo {
        length
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          _id
          name
          slug
          verify_status
          company_type
          logo
          cover
          follow
          job_category {
            title
          }
          office {
            city {
              _id
              name
            }
            district {
              _id
              name
            }
            ward {
              _id
              name
            }
            address
            lat
            lng
          }
          size {
            from
            to
          }
          created_at
          updated_at
        }
      }
    }
  }
`;
export const SINGLE_COMPANY_BY_SLUG = gql`
  query company($slug: String!) {
    company(slug: $slug) {
      _id
      address
      name
      business_code
      name
      slug
      company_type
      verify_status
      premium_status
      size {
        from
        to
      }
      logo
      cover
      website
      follow
      album {
        name
        images {
          src
          description
        }
      }
      benefit {
        id {
          _id
          title
        }
        content
      }
      story {
        title
        content
        media_type
        media_link
      }
      job_category {
        title
      }
      office {
        city {
          _id
          name
        }
        district {
          _id
          name
        }
        ward {
          _id
          name
        }
        address
        lat
        lng
      }
      people {
        name
        content
        position
        avatar
      }
    }
  }
`;
export const SINGLE_COMPANY_BY_ID = gql`
  query company($_id: String!) {
    company(_id: $_id) {
      _id
      name
      business_code
      slug
      verify_status
      logo
      cover
      website
      phone
      follow
      facebook
      youtube
      description
      slogan
      album {
        name
        images {
          src
          description
        }
      }
      benefit {
        id {
          _id
          title
        }
        content
      }
      story {
        title
        content
        media_type
        media_link
      }
      job_category {
        _id
        title
        slug
      }
      created_by {
        first_name
        last_name
        avatar
      }
      office {
        city {
          _id
          name
        }
        district {
          _id
          name
        }
        ward {
          _id
          name
        }
        address
        lat
        lng
      }
      people {
        name
        content
        position
        avatar
      }
    }
  }
`;
export const COMPANY_COUNT = gql`
  query companys($page: Int!, $limit: Int!, $filter: String!) {
    companys(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
        }
      }
      pageInfo {
        length
      }
    }
  }
`;
