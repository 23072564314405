import ApplyListOtherType from "@/store/redux/types/ApplyListOtherType";

export const fetchJobApplyOther = (payload: any) => ({
  type: ApplyListOtherType.FETCH_JOB_APPLY_OTHER,
  payload,
});

export const fetchJobApplyOtherSuccess = (payload: any) => ({
  type: ApplyListOtherType.FETCH_JOB_APPLY_OTHER_SUCCESS,
  payload,
});

export const fetchJobApplyOtherFailure = (payload: any) => ({
  type: ApplyListOtherType.FETCH_JOB_APPLY_OTHER_FAILURE,
  payload,
});

export const setConfirm = (payload: any) => ({
  type: ApplyListOtherType.SET_CONFIRM,
  payload,
});
export const setQuickView = (payload: any) => ({
  type: ApplyListOtherType.SET_QUICK_VIEW,
  payload,
});
export const setPage = (payload: any) => ({
  type: ApplyListOtherType.SET_PAGE,
  payload,
});
