enum CandidateType {
  FETCH_CANDIDATE = "@@/Candidate/FETCH_CANDIDATE",
  FETCH_CANDIDATE_SUCCEED = "@@/Candidate/FETCH_CANDIDATE_SUCCEED",
  FETCH_CANDIDATE_FAILED = "@@/Candidate/FETCH_CANDIDATE_FAILED",

  CREATE_CANDIDATE = "@@/Candidate/CREATE_CANDIDATE",
  CREATE_CANDIDATE_SUCCEED = "@@/Candidate/CREATE_CANDIDATE_SUCCEED",
  CREATE_CANDIDATE_FAILED = "@@/Candidate/CREATE_CANDIDATE_FAILED",

  UPDATE_CANDIDATE = "@@/Candidate/UPDATE_CANDIDATE",
  UPDATE_CANDIDATE_SUCCEED = "@@/Candidate/UPDATE_CANDIDATE_SUCCEED",
  UPDATE_CANDIDATE_FAILED = "@@/Candidate/UPDATE_CANDIDATE_FAILED",
}

interface ICandidateDetail {
  _id: string;
  first_name: string;
  last_name: string;
  birthday: string;
  address: string;
  email: string;
  phone: string;
  current_job: string;
  expect_salary: number;
  interest: string[];
  user: any;
  avatar: string;
  job_open: boolean;
  cv: string;
  photos: string[];
  files: {
    name: string;
    url: string;
  };
  exp: {
    timeline: string;
    position: string;
    description: string;
  }[],
  study: {
    timeline: string;
    position: string;
    description: string;
  }[],
  project: {
    name: string;
    url: string;
    member: string;
    position: string;
    description: string;
  }[],
  note: string[],
  upload_by: any,
  created_at: string;
  updated_at: string;
}

interface ICandidateState {
  detail: ICandidateDetail;
  same_cv: any;
  loading: boolean;
}

export default CandidateType;
export {ICandidateState};
