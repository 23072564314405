import ListCandidateType, {IListCandidateState} from "@/store/redux/types/ListCandidateType";

const defaultState: IListCandidateState = {
  candidate: [],
  length: 0,
  loading: false,
};
export const ListCandidateReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case ListCandidateType.FETCH_LIST_CANDIDATE:
      return {
        ...state,
        loading: true,
      };
    case ListCandidateType.FETCH_LIST_CANDIDATE_SUCCEED:
      return {
        ...state,
        loading: false,
        candidate: action.payload.candidate,
        length: action.payload.length,
      };
    case ListCandidateType.FETCH_LIST_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
