import JobKeywordType, { IJobKeywordState } from "@/store/redux/types/JobKeywordType";

const defaultState: IJobKeywordState = {
  job_keyword: [],
  loading: false
};

export const JobKeywordReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobKeywordType.FETCH_JOB_KEYWORD:
      return {
        ...state,
        loading: true,
      };
    case JobKeywordType.FETCH_JOB_KEYWORD_SUCCEED:
      return {
        ...state,
        job_keyword: action.payload.job_keyword,
        loading: false
      };
    case JobKeywordType.FETCH_JOB_KEYWORD_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
