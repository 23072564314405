import JobTypeType, {IJobTypeState} from "@/store/redux/types/JobTypeType";

const defaultState: IJobTypeState = {
  job_type: [],
  loading: false
};

export const JobTypeReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobTypeType.FETCH_JOB_TYPE:
      return {
        ...state,
        loading: true,
      };
    case JobTypeType.FETCH_JOB_TYPE_SUCCEED:
      return {
        ...state,
        job_type: action.payload.job_type,
        loading: false
      };
    case JobTypeType.FETCH_JOB_TYPE_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
