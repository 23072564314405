import gql from "graphql-tag";

export const COMPANY_VERIFY_UPDATE = gql`
  mutation verify($input: CompanyInput!) {
    companyVerifyUpdate(input: $input) {
      _id
    }
  }
`;

export const COMPANY_PREMIUM_UPDATE = gql`
  mutation premium($input: CompanyInput!) {
    companyPremiumUpdate(input: $input) {
      _id
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createChannel($input: CompanyInput!) {
    companyCreate(input: $input) {
      _id
    }
  }
`;
export const UPDATE_COMPANY = gql`
  mutation updateBasic($input: CompanyInput!) {
    companyUpdate(input: $input) {
      _id
      name
      slug
      verify_status
      logo
      cover
      website
      phone
      follow
      facebook
      youtube
      description
      slogan
      album {
        name
        images {
          src
          description
        }
      }
      benefit {
        id {
          _id
          title
        }
        content
      }
      story {
        title
        content
        media_type
        media_link
      }
      job_category {
        _id
        title
      }
      created_by {
        first_name
        last_name
        avatar
      }
      office {
        city {
          _id
          name
        }
        district {
          _id
          name
        }
        ward {
          _id
          name
        }
        address
        lat
        lng
      }
      people {
        name
        content
        position
        avatar
      }
    }
  }
`;
