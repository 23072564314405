import HomeIcon from "@material-ui/icons/HomeOutlined";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SupervisorAccountRoundedIcon from "@material-ui/icons/SupervisorAccountRounded";
import BusinessIcon from "@material-ui/icons/Business";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import CastForEducationOutlinedIcon from "@material-ui/icons/CastForEducationOutlined";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import FreeBreakfastOutlinedIcon from "@material-ui/icons/FreeBreakfastOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import EventNoteIcon from '@material-ui/icons/EventNote';
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TextFormatIcon from '@material-ui/icons/TextFormat';

export default [
  {
    title: "Bảng điều khiển",
    pages: [
      {
        title: "Tổng quan",
        href: "/overview",
        icon: HomeIcon,
      },
    ],
  },
  {
    title: "Quản lý",
    pages: [
      {
        title: "Tin tuyển dụng",
        href: "/job",
        icon: PostAddIcon,
        children: [
          {
            title: "Hoạt động",
            href: "/job/list",
          },
          {
            title: "Hết hạn",
            href: "/job/expire",
          },
          {
            title: "Thùng rác",
            href: "/job/trash",
          },
        ],
      },
      {
        title: "Người dùng",
        href: "/user",
        icon: SupervisorAccountRoundedIcon,
        children: [
          {
            title: "Tất cả",
            href: "/user/list",
          },
          {
            title: "Spam",
            href: "/user/spam",
          },
        ],
      },
      {
        title: "Ứng tuyển",
        href: "/apply",
        icon: PermContactCalendarOutlinedIcon,
        children: [
          {
            title: "Facebook",
            href: "/facebook-apply/list",
          },
          {
            title: "Google",
            href: "/google-apply/list",
          },
        ],
      },
      {
        title: "Công ty",
        href: "/company",
        icon: BusinessIcon,
        children: [
          {
            title: "Tất cả",
            href: "/company/list",
          },
          {
            title: "Đã xác thực",
            href: "/company/verify",
          },
          {
            title: "Thương hiệu riêng",
            href: "/company/premium",
          },
        ],
      },
      {
        title: "CV ứng viên",
        href: "/cv",
        icon: ChromeReaderModeIcon,
        children: [
          {
            title: "Nhập CV",
            href: "/cv/create",
          },
          {
            title: "Tất cả",
            href: "/cv/list",
          },
        ],
      },
    ],
  },
  {
    title: "Hệ thống",
    pages: [
      {
        title: "Địa chỉ",
        href: "/address",
        icon: LocationOnIcon,
        children: [
          {
            title: "Tỉnh/Thành phố",
            href: "/address/city",
          },
          {
            title: "Quận/Huyện",
            href: "/address/district",
          },
          {
            title: "Phường/Xã",
            href: "/address/ward",
          },
        ],
      },
      {
        title: "Ngành nghề",
        href: "/job-category",
        icon: WorkOutlineIcon,
        children: [
          {
            title: "Thêm mới",
            href: "/job-category/create",
          },
          {
            title: "Danh sách",
            href: "/job-category/list",
          },
        ],
      },
      {
        title: "Cấp bậc",
        href: "/job-level",
        icon: CastForEducationOutlinedIcon,
        children: [
          {
            title: "Thêm mới",
            href: "/job-level/create",
          },
          {
            title: "Danh sách",
            href: "/job-level/list",
          },
        ],
      },
      {
        title: "Phúc lợi",
        href: "/benefit",
        icon: FreeBreakfastOutlinedIcon,
        children: [
          {
            title: "Thêm mới",
            href: "/benefit/create",
          },
          {
            title: "Danh sách",
            href: "/benefit/list",
          },
        ],
      },
      {
        title: "Hoạt động",
        href: "/activity",
        icon: AccessibilityIcon,
        children: [
          {
            title: "Xem tin",
            href: "/activity/job-view",
          },
          {
            title: "Ứng tuyển",
            href: "/activity/job-apply",
          },
          {
            title: "Tìm ứng viên",
            href: "/activity/profile-view",
          },
        ],
      },
      {
        title: "Gửi thông báo",
        href: "/service-worker-notification",
        icon: MessageOutlinedIcon,
        children: [
          {
            title: "Danh sách thông báo",
            href: "/service-notification/list",
          },
          {
            title: "Gửi thông báo mới",
            href: "/service-notification/create",
          },
        ],
      },
      {
        title: "Loại hình công việc",
        href: "/job-type",
        icon: EventNoteIcon,
        children: [
          {
            title: "Danh sách",
            href: "/job-type/list",
          },
          {
            title: "Tạo mới",
            href: "/job-type/create",
          },
        ]
      }, {
        title: "Từ khóa SEO",
        href: "/job-keyword",
        icon: TextFormatIcon,
        children: [
          {
            title: "Danh sách",
            href: "/job-keyword/list",
          },
          {
            title: "Tạo mới",
            href: "/job-keyword/create",
          },
        ],
      },
    ],
  },
];
