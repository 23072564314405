import gql from "graphql-tag";

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($input: NotificationInput!) {
    notificationUpdate(input: $input) {
      read
    }
  }
`;

export const UPDATE_READ_NOTIFICATION = gql`
    mutation updateReadNotification($input: NotificationInput!) {
        notificationRead(input: $input) {
            status
        }
    }
`;

export const UPDATE_READ_ALL_NOTIFICATION = gql`
    mutation updateReadAllNotification {
        notificationReadAll {
            status
        }
    }
`;
