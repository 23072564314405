import ApplyListOtherDetailType from "@/store/redux/types/ApplyListOtherDetailType";

export const fetchJobApplyOtherDetail = (payload: any) => ({
  type: ApplyListOtherDetailType.FETCH_JOB_APPLY_OTHER_DETAIL,
  payload,
});

export const fetchJobApplyOtherDetailSuccess = (payload: any) => ({
  type: ApplyListOtherDetailType.FETCH_JOB_APPLY_OTHER_DETAIL_SUCCESS,
  payload,
});

export const fetchJobApplyOtherDetailFailure = (payload: any) => ({
  type: ApplyListOtherDetailType.FETCH_JOB_APPLY_OTHER_DETAIL_FAILURE,
  payload,
});

export const updateJobApplyOther = (payload: any) => ({
  type: ApplyListOtherDetailType.UPDATE_JOB_APPLY_OTHER_DETAIL,
  payload,
});

export const updateJobApplyOtherSuccess = (payload: any) => ({
  type: ApplyListOtherDetailType.UPDATE_JOB_APPLY_OTHER_DETAIL_SUCCESS,
  payload,
});

export const updateJobApplyOtherFailure = (payload: any) => ({
  type: ApplyListOtherDetailType.UPDATE_JOB_APPLY_OTHER_DETAIL_FAILURE,
  payload,
});

export const sendEmail = (payload: any) => ({
  type: ApplyListOtherDetailType.SEND_EMAIL,
  payload,
});

export const sendEmailSuccess = () => ({
  type: ApplyListOtherDetailType.SEND_EMAIL_SUCCESS,
});

export const sendEmailFailure = () => ({
  type: ApplyListOtherDetailType.SEND_EMAIL_FAILURE,
});
