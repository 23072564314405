enum JobTypeType {
  FETCH_JOB_TYPE = "@@/JobType/FETCH_JOB_TYPE",
  FETCH_JOB_TYPE_SUCCEED = "@@/JobType/FETCH_JOB_TYPE_SUCCEED",
  FETCH_JOB_TYPE_FAILED = "@@/JobType/FETCH_JOB_TYPE_FAILED",
}

interface IJobTypeState {
  job_type: any[];
  loading: boolean;
}

export default JobTypeType;
export {IJobTypeState};
