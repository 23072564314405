import JobTitleType, {IJobTitleState} from "@/store/redux/types/JobTitleType";

const defaultState: IJobTitleState = {
  job_title: [],
  loading: false
};

export const JobTitleReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobTitleType.FETCH_JOB_TITLE:
      return {
        ...state,
        loading: true,
      };
    case JobTitleType.FETCH_JOB_TITLE_SUCCEED:
      return {
        ...state,
        job_title: action.payload.job_title,
        loading: false
      };
    case JobTitleType.FETCH_JOB_TITLE_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
