export const getSort = (sortValue: string) => {
  switch (sortValue) {
    case "created_newest":
      return { created: "newest" };
    case "created_oldest":
      return { created: "oldest" };
    case "salary_low_to_high":
      return { salary: "low_to_high" };
    case "salary_high_to_low":
      return { salary: "high_to_low" };
    case "view_count_low_to_high":
      return { view_count: "low_to_high" };
    case "view_count_high_to_low":
      return { view_count: "high_to_low" };
    case "rating_high_to_low":
      return { rating: "high_to_low" };
    default:
      return { created: "newest" };
  }
};

export const graphqlFilter = (filter: object) => {
  return JSON.stringify(filter).replace(/"/g, `'`);
};

export const toSlug = (str: string) => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
  str = str.replace(/(đ)/g, 'd');

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, '');

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, '-');

  // xóa phần dự - ở đầu
  str = str.replace(/^-+/g, '');

  // xóa phần dư - ở cuối
  str = str.replace(/-+$/g, '');

  // let date = new Date().getTime();
  return str;
  // return str+"-"+date;
};
export const formatMoneyNumberToString = (num: any) => {
  if (!num) {
    return num;
  }
  const numConver = formatMoneyStringToNumber(num.toString());
  return numConver.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatMoneyStringToNumber = (str: string) => {
  if (!str) {
    return str;
  }
  return parseInt(str.split(",").join(""));
};
