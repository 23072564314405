import CoordinateType, {ICoordinateState} from "@/store/redux/types/CoordinateType";

const defaultState: ICoordinateState = {
  address: []
}

export const CoordinateReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case CoordinateType.FETCH_ADDRESS_TEXT:
      return {
        ...state,
        loading: true,
      }
    case CoordinateType.FETCH_ADDRESS_TEXT_SUCCEED:
      return {
        ...state,
        loading: false,
        address: action.payload,
      }
    case CoordinateType.FETCH_ADDRESS_TEXT_FAILED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state;
  }
}
