import gql from "graphql-tag";

export const PAGINATE_JOB_APPLY = gql`
  query paginateJobApply($page: Int!, $limit: Int!, $filter: String!) {
    jobApplys(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          job_post {
            title
            slug
            number
            description
            requirement
            image
            salary {
              min
              max
              show
            }
            video
            end_date
            view_count
            status
            seo_title
            seo_description
            created_at
            updated_at
          }
          user {
            _id
            info {
              job_open
              avatar
              name
              birthday
              gender
              date
              phone
              website
              intro
            }
            email
            psid
            first_name
            last_name
            birth_day
            avatar
            gender
            login_type
            spam
          }
          status
          email
          file
          description
          created_at
          updated_at
        }
      }
      pageInfo {
        length
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const SINGLE_JOB_APPLY = gql`
  query jobApply($_id: String!) {
    jobApply(_id: $_id) {
      _id
      job_post {
        title
        slug
        number
        description
        requirement
        image
        salary {
          min
          max
          show
        }
        company {
          name
          ref {
            name
            business_code
            slogan
            logo
            cover
            website
            email
            phone
            facebook
            youtube
            description
            company_type
            verify_status
            premium_status
            slug
            follow
            seo_title
            seo_description
          }
        }
        video
        end_date
        view_count
        status
        seo_title
        seo_description
        created_at
        updated_at
      }
      user {
        _id
        info {
          job_open
          avatar
          name
          birthday
          gender
          date
          phone
          website
          intro
          address {
            lat
            lng
            text
          }
        }
        email
        psid
        first_name
        last_name
        birth_day
        avatar
        gender
        login_type
        spam
      }
      status
      email
      file
      description
      created_at
      updated_at
    }
  }
`;

export const USER_APPLY_COUNT = gql`
  query jobApplys($page: Int!, $limit: Int!, $filter: String!) {
    jobApplys(page: $page, filter: $filter, limit: $limit) {
      pageInfo {
        length
      }
    }
  }
`;
