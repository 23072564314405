import ApplyListType from "@/store/redux/types/ApplyListType";

import { takeLatest, put, all, fork } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import { PAGINATE_JOB_APPLY } from "@/graphql/job_apply/query";
import { fetchJobApplySuccess, setPage } from "@/store/redux/actions/ApplyListAction";

function* fetchJobApplySaga(action: any) {
  try {
    yield put(setPage(action.payload.page));
    const r = yield apolloClient.query({ query: PAGINATE_JOB_APPLY, variables: action.payload });
    yield put(fetchJobApplySuccess(r.data.jobApplys));
  } catch (err) {
    console.log(err);
  }
}

function* watchApplyListSaga() {
  yield takeLatest(ApplyListType.FETCH_JOB_APPLY, fetchJobApplySaga);
}

function* ApplyListSaga() {
  yield all([fork(watchApplyListSaga)]);
}

export default ApplyListSaga;
