enum NotificationType {
  FETCH_NOTIFICATION = "@@Notification/FETCH_NOTIFICATION",
  FETCH_NOTIFICATION_SUCCEED = "@@Notification/FETCH_NOTIFICATION_SUCCEED",
  FETCH_NOTIFICATION_FAILED = "@@Notification/FETCH_NOTIFICATION_FAILED",

  UPDATE_READ_NOTIFICATION = "@@@Notification/UPDATE_READ_NOTIFICATION",
  UPDATE_READ_NOTIFICATION_SUCCEED = "@@@Notification/UPDATE_READ_NOTIFICATION_SUCCEED",
  UPDATE_READ_NOTIFICATION_FAILED = "@@Notification/UPDATE_READ_NOTIFICATION_FAILED",

  FETCH_UNREAD_NOTIFICATION_LENGTH = "@@Notification/FETCH_UNREAD_NOTIFICATION_LENGTH",
  FETCH_UNREAD_NOTIFICATION_LENGTH_SUCCEED = "@@Notification/FETCH_UNREAD_NOTIFICATION_LENGTH_SUCCEED",
  FETCH_UNREAD_NOTIFICATION_LENGTH_FAILED = "@@Notification/FETCH_UNREAD_NOTIFICATION_LENGTH_FAILED",

  UPDATE_READ_ALL_NOTIFICATION = "@@@Notification/UPDATE_READ_ALL_NOTIFICATION",
  UPDATE_READ_ALL_NOTIFICATION_SUCCEED = "@@@Notification/UPDATE_READ_ALL_NOTIFICATION_SUCCEED",
  UPDATE_READ_ALL_NOTIFICATION_FAILED = "@@Notification/UPDATE_READ_ALL_NOTIFICATION_FAILED",

}

interface INotificationState {
  notification: any[];
  length: number;
  unread_length: number;
  loading: boolean;
}

export default NotificationType;
export {INotificationState};
