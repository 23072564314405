import {fetchCompanyFailed, updateCompanyFailed} from "../../actions/CompanyAction/index";
import CompanyType from "@/store/redux/types/CompanyType";
import {CREATE_COMPANY, UPDATE_COMPANY} from "@/graphql/company/mutation";
import {SINGLE_COMPANY_BY_ID} from "@/graphql/company/query";

import {all, fork, put, takeLatest} from "redux-saga/effects";
import {apolloClient} from "@/utils/apolloClient";
import {
  createCompanyFailed,
  createCompanySucceed,
  fetchCompanySucceed,
  updateCompanySucceed,
} from "@/store/redux/actions/CompanyAction";
import {enqueueSnackbar} from "@/store/redux/actions/GlobalToastAction";
import {closeModal} from "@/store/redux/actions/GlobalModalAction";

function* createCompanySaga(action: any) {
  try {
    let r = yield apolloClient.mutate({mutation: CREATE_COMPANY, variables: {input: action.payload}});
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createCompanySucceed());
    yield (window.location.href = `/company/${r.data.companyCreate._id}`);
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createCompanyFailed(e));
  }
}

function* fetchCompanySaga(action: any) {
  try {
    let r = yield apolloClient.query({query: SINGLE_COMPANY_BY_ID, variables: action.payload.variables});
    yield put(fetchCompanySucceed({detail: r.data.company}));
  } catch (e) {
    yield put(fetchCompanyFailed());
  }
}


function* updateCompanySaga(action: any) {
  try {
    let r = yield apolloClient.mutate({mutation: UPDATE_COMPANY, variables: {input: action.payload}});
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateCompanySucceed({detail: r.data.companyUpdate}));
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateCompanyFailed());
  }
}


function* watchFetchCompanySaga() {
  yield takeLatest([CompanyType.CREATE_COMPANY], createCompanySaga);
  yield takeLatest([CompanyType.FETCH_COMPANY], fetchCompanySaga);
  yield takeLatest([CompanyType.UPDATE_COMPANY], updateCompanySaga);
}

function* CompanySaga() {
  yield all([fork(watchFetchCompanySaga)]);
}

export default CompanySaga;
