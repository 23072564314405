import { all, fork } from "redux-saga/effects";
import CompanySaga from "@/store/redux/sagas/CompanySaga";
import ApplyListSaga from "@/store/redux/sagas/ApplyListSaga";
import ApplyListDetailSaga from "@/store/redux/sagas/ApplyListDetailSaga";
import ApplyListOtherSaga from "@/store/redux/sagas/ApplyListOtherSaga";
import ApplyListOtherDetailSaga from "@/store/redux/sagas/ApplyListOtherDetailSaga";
import coordinateSaga from "@/store/redux/sagas/CoordinateSaga";
import addressSaga from "@/store/redux/sagas/AddressSaga";
import listCompanySaga from "@/store/redux/sagas/ListCompanySaga";
import jobCategorySaga from "@/store/redux/sagas/JobCategorySaga";
import jobTitleSaga from "@/store/redux/sagas/JobTitleSaga";
import notificationSaga from "@/store/redux/sagas/NotificationSaga";
import jobTypeSaga from "@/store/redux/sagas/JobTypeSaga";
import jobLevelSaga from "@/store/redux/sagas/JobLevelSaga";
import jobPostSaga from "@/store/redux/sagas/JobPostSaga";
import listJobPostSaga from "@/store/redux/sagas/ListJobPostSaga";
import listCandidateSaga from "@/store/redux/sagas/ListCandidateSaga";
import candidateSaga from "@/store/redux/sagas/CandidateSaga";
import UserSage from "@/store/redux/sagas/UserSaga";

function* rootSaga() {
  yield all([
    fork(CompanySaga),
    fork(ApplyListSaga),
    fork(ApplyListDetailSaga),
    fork(ApplyListOtherSaga),
    fork(ApplyListOtherDetailSaga),
    fork(coordinateSaga),
    fork(addressSaga),
    fork(listCompanySaga),
    fork(jobCategorySaga),
    fork(jobTitleSaga),
    fork(notificationSaga),
    fork(jobTypeSaga),
    fork(jobLevelSaga),
    fork(jobPostSaga),
    fork(listJobPostSaga),
    fork(listCandidateSaga),
    fork(candidateSaga),
    fork(UserSage),
  ]);
}

export default rootSaga;
