import JobKeywordType from "@/store/redux/types/JobKeywordType";
import { takeLatest, put, fork, all, delay } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import { fetchJobKeywordFailed, fetchJobKeywordSucceed } from "@/store/redux/actions/JobKeywordAction";
import { PAGINATE_JOB_KEYWORD } from "@/graphql/job_keyword/query";

function* fetchJobKeyword(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({ query: PAGINATE_JOB_KEYWORD, variables: action.payload.variables });
    yield put(
      fetchJobKeywordSucceed({
        job_keyword: r.data.jobTypes.edges,
      })
    );
  } catch (e) {
    yield put(fetchJobKeywordFailed());
  }
}

function* watchFetchJobKeyword() {
  yield takeLatest(JobKeywordType.FETCH_JOB_KEYWORD, fetchJobKeyword);
}

function* jobTypeSaga() {
  yield all([fork(watchFetchJobKeyword)]);
}

export default jobTypeSaga;
