import ApplyListOtherDetailType from "@/store/redux/types/ApplyListOtherDetailType";

import { takeLatest, put, all, fork, takeEvery, select } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import { UPDATE_STATUS_JOB_APPLY_OTHER } from "@/graphql/job_apply_other/mutation";
import { SINGLE_JOB_APPLY_OTHER } from "@/graphql/job_apply_other/query";

import {
  fetchJobApplyOtherDetailSuccess,
  fetchJobApplyOtherDetailFailure,
  updateJobApplyOtherSuccess,
  sendEmailSuccess,
  sendEmailFailure,
} from "@/store/redux/actions/ApplyListOtherDetailAction";
import axios from "axios";

function* fetchJobApplyOtherListOtherDetailSaga(action: any) {
  try {
    let r = yield apolloClient.query({ query: SINGLE_JOB_APPLY_OTHER, variables: { _id: action.payload } });
    yield put(fetchJobApplyOtherDetailSuccess(r.data.jobApplyOther));
  } catch (e) {
    yield put(fetchJobApplyOtherDetailFailure({ message: e.message }));
  }
}

function* updateJobApplyOtherListOtherDetailSaga(action: any) {
  try {
    yield apolloClient.mutate({
      mutation: UPDATE_STATUS_JOB_APPLY_OTHER,
      variables: { input: action.payload },
    });
    let { job_detail } = yield select(function (state: any) {
      return state.applyDetail;
    });
    let stateUpdate = Object.assign({ ...job_detail, status: action.payload.status });
    yield put(updateJobApplyOtherSuccess(stateUpdate));
  } catch (e) {}
}
function* sendEmailNoreplySaga(action: any) {
  try {
    let r = yield axios.post(`${process.env.REACT_APP_API_URL}/noreply/send`, { data: action.payload });
    if (r.status === 200) {
      yield put(sendEmailSuccess());
    }
  } catch (e) {
    yield put(sendEmailFailure());
  }
}

function* watchApplyListOtherDetailSaga() {
  yield takeEvery(ApplyListOtherDetailType.FETCH_JOB_APPLY_OTHER_DETAIL, fetchJobApplyOtherListOtherDetailSaga);
  yield takeEvery(ApplyListOtherDetailType.UPDATE_JOB_APPLY_OTHER_DETAIL, updateJobApplyOtherListOtherDetailSaga);
  yield takeLatest(ApplyListOtherDetailType.SEND_EMAIL, sendEmailNoreplySaga);
}

function* ApplyListOtherDetailSaga() {
  yield all([fork(watchApplyListOtherDetailSaga)]);
}

export default ApplyListOtherDetailSaga;
