import gql from "graphql-tag";

export const PAGINATE_JOB_APPLY_OTHER = gql`
  query paginateJobApplyOther($page: Int!, $limit: Int!, $filter: String!) {
    jobApplyOthers(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          job_post
          user {
            _id
            info {
              job_open
              avatar
              name
              birthday
              gender
              date
              phone
              website
              intro
            }
            email
            psid
            first_name
            last_name
            birth_day
            avatar
            gender
            login_type
            spam
          }
          status
          email
          file
          description
          created_at
          updated_at
        }
      }
      pageInfo {
        length
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const SINGLE_JOB_APPLY_OTHER = gql`
  query jobApplyOther($_id: String!) {
    jobApplyOther(_id: $_id) {
      _id
      job_post

      user {
        _id
        info {
          job_open
          avatar
          name
          birthday
          gender
          date
          phone
          website
          intro
          address {
            lat
            lng
            text
          }
        }
        email
        psid
        first_name
        last_name
        birth_day
        avatar
        gender
        login_type
        spam
      }
      status
      email
      file
      description
      created_at
      updated_at
    }
  }
`;

export const USER_APPLY_OTHER_COUNT = gql`
  query jobApplyOthers($page: Int!, $limit: Int!, $filter: String!) {
    jobApplyOthers(page: $page, filter: $filter, limit: $limit) {
      pageInfo {
        length
      }
    }
  }
`;
