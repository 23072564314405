import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/overview" />,
  },
  {
    path: "/notification",
    exact: true,
    component: lazy(() => import("@/views/Notification/Notification")),
  },
  {
    path: `/overview`,
    exact: true,
    component: lazy(() => import("@/views/Overview")),
  },
  {
    path: `/user/list`,
    exact: true,
    component: lazy(() => import("@/views/UserList/UserList")),
  },
  {
    path: `/user/spam`,
    exact: true,
    component: lazy(() => import("@/views/UserSpamList/UserSpamList")),
  },
  {
    path: `/user/:slug`,
    exact: true,
    component: lazy(() => import("@/views/UserDetail/UserDetail")),
  },
  {
    path: `/facebook-apply/list`,
    exact: true,
    component: lazy(() => import("@/views/ApplyFacebookList/ApplyFacebookList")),
  },
  {
    path: `/google-apply/list`,
    exact: true,
    component: lazy(() => import("@/views/ApplyGoogleList/ApplyGoogleList")),
  },
  {
    path: `/google-detail/:slug`,
    exact: true,
    component: lazy(() => import("@/views/ApplyGoogleDetail/ApplyGoogleDetail")),
  },
  {
    path: `/job/list`,
    exact: true,
    component: lazy(() => import("@/views/JobList/JobList")),
  },
  {
    path: `/job/expire`,
    exact: true,
    component: lazy(() => import("@/views/JobListExpire/JobListExpire")),
  },
  {
    path: `/job/trash`,
    exact: true,
    component: lazy(() => import("@/views/JobListTrash/JobListTrash")),
  },
  {
    path: `/job/:slug`,
    exact: true,
    component: lazy(() => import("@/views/JobEdit/JobEdit")),
  },
  {
    path: `/company/list`,
    exact: true,
    component: lazy(() => import("@/views/CompanyList/List")),
  },
  {
    path: `/company/verify`,
    exact: true,
    component: lazy(() => import("@/views/CompanyVerifyList/List")),
  },
  {
    path: `/company/premium`,
    exact: true,
    component: lazy(() => import("@/views/CompanyPremiumList/List")),
  },
  {
    path: `/company-feature/list`,
    exact: true,
    component: lazy(() => import("@/views/CompanyFeatureList/List")),
  },
  {
    path: `/company-feature/create`,
    exact: true,
    component: lazy(() => import("@/views/CompanyFeatureCreate/Create")),
  },
  {
    path: `/company-feature/:slug`,
    exact: true,
    component: lazy(() => import("@/views/CompanyFeatureUpdate/Update")),
  },
  {
    path: `/job-category/list`,
    exact: true,
    component: lazy(() => import("@/views/JobCategoryList/List")),
  },
  {
    path: `/job-category/create`,
    exact: true,
    component: lazy(() => import("@/views/JobCategoryCreate/Create")),
  },
  {
    path: `/job-category/:slug`,
    exact: true,
    component: lazy(() => import("@/views/JobCategoryEdit/Edit")),
  },
  {
    path: `/job-level/list`,
    exact: true,
    component: lazy(() => import("@/views/JobLevelList/List")),
  },
  {
    path: `/job-level/create`,
    exact: true,
    component: lazy(() => import("@/views/JobLevelCreate/Create")),
  },
  {
    path: `/job-level/:slug`,
    exact: true,
    component: lazy(() => import("@/views/JobLevelEdit/Edit")),
  },
  {
    path: `/benefit/list`,
    exact: true,
    component: lazy(() => import("@/views/BenefitList/List")),
  },
  {
    path: `/benefit/create`,
    exact: true,
    component: lazy(() => import("@/views/BenefitCreate/Create")),
  },
  {
    path: `/benefit/:slug`,
    exact: true,
    component: lazy(() => import("@/views/BenefitEdit/Edit")),
  },
  {
    path: `/activity/job-apply`,
    exact: true,
    component: lazy(() => import("@/views/JobApplyList/List")),
  },
  {
    path: `/activity/job-view`,
    exact: true,
    component: lazy(() => import("@/views/JobViewList/List")),
  },
  {
    path: `/activity/profile-view`,
    exact: true,
    component: lazy(() => import("@/views/ProfileViewList/List")),
  },
  {
    path: "/order/list",
    exact: true,
    component: lazy(() => import("@/views/Order/Order")),
  },
  {
    path: "/order/:slug",
    exact: true,
    component: lazy(() => import("@/views/OrderDetail/OrderDetail")),
  },
  {
    path: "/service-notification/list",
    exact: true,
    component: lazy(() => import("@/views/ServiceNotificationList/List")),
  },
  {
    path: "/service-notification/create",
    exact: true,
    component: lazy(() => import("@/views/ServiceNotificationCreate/Create")),
  },
  {
    path: "/cv/create",
    exact: true,
    component: lazy(() => import("@/views/CVCreate/CVCreate")),
  },
  {
    path: "/address/city",
    exact: true,
    component: lazy(() => import("@/views/AddressList/City/CityList")),
  },
  {
    path: "/address/city/create",
    exact: true,
    component: lazy(() => import("@/views/AddressCreate/City/Create")),
  },
  {
    path: "/address/district",
    exact: true,
    component: lazy(() => import("@/views/AddressList/District/DistrictList")),
  },
  {
    path: "/address/district/create",
    exact: true,
    component: lazy(() => import("@/views/AddressCreate/District/Create")),
  },
  {
    path: "/address/ward",
    exact: true,
    component: lazy(() => import("@/views/AddressList/Ward/WardList")),
  },
  {
    path: "/address/ward/create",
    exact: true,
    component: lazy(() => import("@/views/AddressCreate/Ward/Create")),
  },
  {
    path: `/city/:slug`,
    exact: true,
    component: lazy(() => import("@/views/AddressEdit/City/CityEdit")),
  },
  {
    path: `/district/:slug`,
    exact: true,
    component: lazy(() => import("@/views/AddressEdit/District/DistrictEdit")),
  },
  {
    path: `/ward/:slug`,
    exact: true,
    component: lazy(() => import("@/views/AddressEdit/Ward/WardEdit")),
  },
  {
    path: "/job-type/list",
    exact: true,
    component: lazy(() => import("@/views/JobTypeList/List")),
  },
  {
    path: "/job-type/create",
    exact: true,
    component: lazy(() => import("@/views/JobTypeCreate/Create")),
  },
  {
    path: "/job-type/:slug",
    exact: true,
    component: lazy(() => import("@/views/JobTypeEdit/Edit")),
  },
  {
    path: "/cv/list",
    exact: true,
    component: lazy(() => import("@/views/CVList/CVList")),
  },
  {
    path: "/cv/:slug",
    exact: true,
    component: lazy(() => import("@/views/CVEdit/Edit")),
  },
  {
    path: "/job-keyword/list",
    exact: true,
    component: lazy(() => import("@/views/JobKeywordList/List")),
  },
  {
    path: "/job-keyword/create",
    exact: true,
    component: lazy(() => import("@/views/JobKeywordCreate/Create")),
  },
  {
    path: "/job-keyword/:slug",
    exact: true,
    component: lazy(() => import("@/views/JobKeywordEdit/Edit")),
  },
];

export default routes;
