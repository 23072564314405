import ApplyListOtherType, { ApplyListOtherState } from "@/store/redux/types/ApplyListOtherType";
const defaultState: ApplyListOtherState = {
  job_apply: [],
  hasNextPage: false,
  count: 0,
  page: 1,
  loading: false,
};
export const ApplyListOtherReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case ApplyListOtherType.FETCH_JOB_APPLY_OTHER:
      return {
        ...state,
        loading: true,
      };
    case ApplyListOtherType.FETCH_JOB_APPLY_OTHER_SUCCESS:
      return {
        ...state,
        loading: false,
        job_apply: action.payload.edges,
        count: action.payload.pageInfo.length,
        hasNextPage: action.payload.pageInfo.hasNextPage,
      };
    case ApplyListOtherType.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
};
