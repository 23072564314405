import gql from "graphql-tag";

export const PAGINATE_JOB_LEVEL = gql`
    query jobLevels($page: Int!, $limit: Int!, $filter: String!) {
        jobLevels(filter: $filter, page: $page, limit: $limit) {
            pageInfo {
                length
                hasNextPage
                hasPreviousPage
            }
            edges {
                node {
                    _id
                    title
                    slug
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const SINGLE_JOB_LEVEL = gql`
    query jobLevel ($_id: String!) {
        jobLevel(_id: $_id) {
            _id
            title
            slug
        }
    }
`;
