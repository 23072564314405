import gql from "graphql-tag";

export const UPDATE_JOB_APPLY_OTHER = gql`
  mutation updateJobApplyOther($input: JobApplyOtherInput!) {
    jobApplyUpdate(input: $input) {
      _id
    }
  }
`;
export const UPDATE_STATUS_JOB_APPLY_OTHER = gql`
  mutation updateJobApplyOther($input: JobApplyOtherInput!) {
    jobStatusApplyOtherUpdate(input: $input) {
      status
    }
  }
`;
