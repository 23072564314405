import gql from "graphql-tag";

export const PAGINATE_WARD = gql`
    query paginateWard($page: Int!, $limit: Int!, $filter: String!) {
        wards(page: $page, filter: $filter, limit: $limit) {
            edges {
                node {
                    _id
                    name
                }
            }
        }
    }
`;

export const PAGINATE_ADDRESS_WARD = gql`
    query paginateWard($page: Int!, $limit: Int!, $filter: String!) {
        wards(page: $page, filter: $filter, limit: $limit) {
            edges {
                node {
                    _id
                    name
                    district {
                        name
                        city {
                            name
                        }
                    }
                }
            }
            pageInfo {
                length
            }
        }
    }
`;

export const SINGLE_WARD = gql`
    query ward($_id: String!) {
        ward(_id: $_id) {
            _id
            name
            title
            description
            slug
            focus_keyword
            seo_title
            seo_description
            image
            image_description
        }
    }
`;
