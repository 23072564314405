import AddressType from "@/store/redux/types/AddressType";

export const fetchCity = (payload: any) => ({
  type: AddressType.FETCH_CITY,
  payload
});

export const fetchCitySucceed = (payload: any) => ({
  type: AddressType.FETCH_CITY_SUCCEED,
  payload
});

export const fetchCityFailed = () => ({
  type: AddressType.FETCH_CITY_FAILED,
});

export const fetchDistrict = (payload: any) => ({
  type: AddressType.FETCH_DISTRICT,
  payload
});

export const fetchDistrictSucceed = (payload: any) => ({
  type: AddressType.FETCH_DISTRICT_SUCCEED,
  payload
});

export const fetchDistrictFailed = () => ({
  type: AddressType.FETCH_DISTRICT_FAILED,
});

export const fetchWard = (payload: any) => ({
  type: AddressType.FETCH_WARD,
  payload
});

export const fetchWardSucceed = (payload: any) => ({
  type: AddressType.FETCH_WARD_SUCCEED,
  payload
});

export const fetchWardFailed = () => ({
  type: AddressType.FETCH_WARD_FAILED,
});
