import { fetchJobPostFailed, updateJobPostFailed } from "../../actions/JobPostAction/index";
import JobPostType from "@/store/redux/types/JobPostType";
import { CREATE_JOB_POST, UPDATE_JOB_POST } from "@/graphql/job_post/mutation";
import { SINGLE_JOB_POST_BY_ID } from "@/graphql/job_post/query";

import { all, fork, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import {
  createJobPostFailed,
  createJobPostSucceed,
  fetchJobPostSucceed,
  updateJobPostSucceed,
} from "@/store/redux/actions/JobPostAction";
import { enqueueSnackbar } from "@/store/redux/actions/GlobalToastAction";
import { closeModal } from "@/store/redux/actions/GlobalModalAction";

function* createJobPostSaga(action: any) {
  try {
    yield apolloClient.mutate({ mutation: CREATE_JOB_POST, variables: { input: action.payload } });
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createJobPostSucceed());
    yield (window.location.href = `/job/list`);
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createJobPostFailed(e));
  }
}

function* fetchJobPostSaga(action: any) {
  try {
    let r = yield apolloClient.query({ query: SINGLE_JOB_POST_BY_ID, variables: action.payload.variables });
    yield put(fetchJobPostSucceed({ detail: r.data.jobPost }));
  } catch (e) {
    console.log(e);
    yield put(fetchJobPostFailed());
  }
}

function* updateJobPostSaga(action: any) {
  try {
    let r = yield apolloClient.mutate({ mutation: UPDATE_JOB_POST, variables: { input: action.payload } });
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateJobPostSucceed({ detail: r.data.jobPostUpdate }));
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateJobPostFailed());
  }
}

function* watchFetchJobPostSaga() {
  yield takeLatest([JobPostType.CREATE_JOB_POST], createJobPostSaga);
  yield takeLatest(JobPostType.FETCH_JOB_POST, fetchJobPostSaga);
  yield takeLatest([JobPostType.UPDATE_JOB_POST], updateJobPostSaga);
}

function* jobPostSaga() {
  yield all([fork(watchFetchJobPostSaga)]);
}

export default jobPostSaga;
