enum CVType {
  FETCH_CV = "@@/CV/FETCH_CV",
  FETCH_CV_SUCCEED = "@@/CV/FETCH_CV_SUCCEED",
  FETCH_CV_FAILED = "@@/CV/FETCH_CV_FAILED",

  UPDATE_CV = "@@/CV/UPDATE_CV",
  UPDATE_CV_SUCCEED = "@@/CV/UPDATE_CV_SUCCEED",
  UPDATE_CV_FAILED = "@@/CV/UPDATE_CV_FAILED",

  CREATE_CV = "@@/CV/CREATE_CV",
  CREATE_CV_SUCCEED = "@@/CV/CREATE_CV_SUCCEED",
  CREATE_CV_FAILED = "@@/CV/CREATE_CV_FAILED",
}

interface ICVState {
  detail: any;
  loading: boolean;
}

export default CVType;
export {ICVState};
