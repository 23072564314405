import UserType from "@/store/redux/types/UserType";

export const fetchUser = () => ({
  type: UserType.FETCH_USER,
});

export const fetchUserSuccess = (user: any) => ({
  type: UserType.FETCH_USER_SUCCESS,
  payload: { ...user },
});
