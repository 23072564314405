import UserType from "@/store/redux/types/UserType";

const initState = {};
export const UserReducer = (state = initState, action: any) => {
  switch (action.type) {
    case UserType.FETCH_USER_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
