import gql from "graphql-tag";

export const UPDATE_JOB_APPLY = gql`
  mutation updateJobApply($input: JobApplyInput!) {
    jobApplyUpdate(input: $input) {
      _id
    }
  }
`;
export const UPDATE_STATUS_JOB_APPLY = gql`
  mutation updateJobApply($input: JobApplyInput!) {
    jobStatusApplyUpdate(input: $input) {
      status
    }
  }
`;
