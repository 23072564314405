import AddressType, {IAddressState} from "@/store/redux/types/AddressType";

const defaultState: IAddressState = {
  city: [],
  district: [],
  ward: [],
  loading: false
};

export const AddressReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case AddressType.FETCH_CITY:
      return {
        ...state,
        loading: true,
      };
    case AddressType.FETCH_CITY_SUCCEED:
      return {
        ...state,
        city: action.payload.edges,
        district: [],
        ward: [],
        loading: false
      };
    case AddressType.FETCH_CITY_FAILED:
      return {
        ...state,
        loading: false
      };

    case AddressType.FETCH_DISTRICT:
      return {
        ...state,
        loading: true,
      };
    case AddressType.FETCH_DISTRICT_SUCCEED:
      return {
        ...state,
        district: action.payload.edges,
        ward: [],
        loading: false
      };
    case AddressType.FETCH_DISTRICT_FAILED:
      return {
        ...state,
        loading: false
      };

    case AddressType.FETCH_WARD:
      return {
        ...state,
        loading: true,
      };
    case AddressType.FETCH_WARD_SUCCEED:
      return {
        ...state,
        ward: action.payload.edges,
        loading: false
      };
    case AddressType.FETCH_WARD_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
