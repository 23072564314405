import JobCategoryType from "@/store/redux/types/JobCategoryType";
import {takeLatest, put, fork, all, delay} from "redux-saga/effects";
import {apolloClient} from "@/utils/apolloClient";
import {fetchJobCategoryFailed, fetchJobCategorySucceed} from "@/store/redux/actions/JobCategoryAction";
import {PAGINATE_JOB_CATEGORY} from "@/graphql/job_category/query";

function* fetchJobCategory(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({query: PAGINATE_JOB_CATEGORY, variables: action.payload.variables});
    yield put(fetchJobCategorySucceed({
      job_category: r.data.jobCategorys.edges,
      hasNextPage: r.data.jobCategorys.pageInfo.hasNextPage,
    }))
  } catch (e) {
    yield put(fetchJobCategoryFailed())
  }
}

function* watchFetchJobCategory() {
  yield takeLatest(JobCategoryType.FETCH_JOB_CATEGORY, fetchJobCategory)
}

function* jobCategorySaga() {
  yield all([
    fork(watchFetchJobCategory),
  ])
}

export default jobCategorySaga;
