enum JobPostType {
  FETCH_JOB_POST = "@@/JobPost/FETCH_JOB_POST",
  FETCH_JOB_POST_SUCCEED = "@@/JobPost/FETCH_JOB_POST_SUCCEED",
  FETCH_JOB_POST_FAILED = "@@/JobPost/FETCH_JOB_POST_FAILED",

  UPDATE_JOB_POST = "@@/JobPost/UPDATE_JOB_POST",
  UPDATE_JOB_POST_SUCCEED = "@@/JobPost/UPDATE_JOB_POST_SUCCEED",
  UPDATE_JOB_POST_FAILED = "@@/JobPost/UPDATE_JOB_POST_FAILED",

  CREATE_JOB_POST = "@@/JobPost/CREATE_JOB_POST",
  CREATE_JOB_POST_SUCCEED = "@@/JobPost/CREATE_JOB_POST_SUCCEED",
  CREATE_JOB_POST_FAILED = "@@/JobPost/CREATE_JOB_POST_FAILED",
}

interface IJobPostState {
  detail: any;
  loading: boolean;
}

export default JobPostType;
export {IJobPostState};
