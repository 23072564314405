import JobCategoryType, {IJobCategoryState} from "@/store/redux/types/JobCategoryType";

const defaultState: IJobCategoryState = {
  job_category: [],
  loading: false
};

export const JobCategoryReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobCategoryType.FETCH_JOB_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case JobCategoryType.FETCH_JOB_CATEGORY_SUCCEED:
      return {
        ...state,
        job_category: action.payload.job_category,
        hasNextPage: action.payload.hasNextPage,
        loading: false
      };
    case JobCategoryType.FETCH_JOB_CATEGORY_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
