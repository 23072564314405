enum ApplyListOtherType {
  FETCH_JOB_APPLY_OTHER = "@@/ApplyListOther/FETCH_JOB_APPLY_OTHER",
  FETCH_JOB_APPLY_OTHER_SUCCESS = "@@/ApplyListOther/FETCH_JOB_APPLY_OTHER_SUCCESS",
  FETCH_JOB_APPLY_OTHER_FAILURE = "@@/ApplyListOther/FETCH_JOB_APPLY_OTHER_FAILURE",

  SET_QUICK_VIEW = "@@/ApplyListOther/SET_QUICK_VIEW",
  SET_CONFIRM = "@@/ApplyListOther/SET_CONFIRM",
  SET_PAGE = "@@/ApplyListOther/SET_PAGE",
}

interface ApplyListOtherState {
  job_apply: any[];
  count: number;
  page: number;
  hasNextPage: boolean;
  loading: boolean;
}

export default ApplyListOtherType;
export { ApplyListOtherState };
