enum JobKeywordType {
  FETCH_JOB_KEYWORD = "@@/JobKeyword/FETCH_JOB_KEYWORD",
  FETCH_JOB_KEYWORD_SUCCEED = "@@/JobKeyword/FETCH_JOB_KEYWORD_SUCCEED",
  FETCH_JOB_KEYWORD_FAILED = "@@/JobKeyword/FETCH_JOB_KEYWORD_FAILED",
}

interface IJobKeywordState {
  job_keyword: any[];
  loading: boolean;
}

export default JobKeywordType;
export {IJobKeywordState};
