import ListJobPostType from "@/store/redux/types/ListJobPostType";
import {all, fork, put, takeLatest, delay} from "redux-saga/effects";
import {apolloClient} from "@/utils/apolloClient";
import {fetchListJobPostFailed, fetchListJobPostSucceed} from "@/store/redux/actions/ListJobPostAction";
import {PAGINATE_JOB_POST} from "@/graphql/job_post/query";

function* fetchListJobPostSaga(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({query: PAGINATE_JOB_POST, variables: action.payload.variables});
    yield put(fetchListJobPostSucceed({job_post: r.data.jobPosts.edges, length: r.data.jobPosts.pageInfo.length}))
  } catch (e) {
    yield put(fetchListJobPostFailed())
  }
}

function* watchFetchListJobPostSaga() {
  yield takeLatest(ListJobPostType.FETCH_LIST_JOB_POST, fetchListJobPostSaga)
}

function* listJobPostSaga() {
  yield all([fork(watchFetchListJobPostSaga)]);
}

export default listJobPostSaga;
