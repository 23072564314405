enum JobLevelType {
  FETCH_JOB_LEVEL = "@@/JobLevel/FETCH_JOB_LEVEL",
  FETCH_JOB_LEVEL_SUCCEED = "@@/JobLevel/FETCH_JOB_LEVEL_SUCCEED",
  FETCH_JOB_LEVEL_FAILED = "@@/JobLevel/FETCH_JOB_LEVEL_FAILED",
}

interface IJobLevelState {
  job_level: any[];
  loading: boolean;
}

export default JobLevelType;
export {IJobLevelState};
