import gql from "graphql-tag";

export const PAGINATE_JOB_KEYWORD = gql`
  query jobKeywords($page: Int!, $limit: Int!, $filter: String!) {
    jobKeywords(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          title
          keyword
          updated_at
        }
      }
      pageInfo {
        length
      }
    }
  }
`;

export const JOB_KEYWORD = gql`
  query jobKeyword($id: String!) {
    jobKeyword(_id: $id) {
      _id
      title
      slug
      keyword
      seo_title
      seo_description
    }
  }
`;
