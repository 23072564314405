import CVType, {ICVState} from "@/store/redux/types/CVType";

const defaultState: ICVState = {
  detail: {},
  loading: false,
};
export const CVReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case CVType.CREATE_CV:
      return {
        ...state,
        loading: true,
      };
    case CVType.CREATE_CV_SUCCEED:
      return {
        ...state,
        loading: false,
      };
    case CVType.CREATE_CV_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CVType.FETCH_CV:
      return {
        ...state,
        loading: true,
      };
    case CVType.FETCH_CV_SUCCEED:
      return {
        ...state,
        loading: false,
        detail: action.payload.detail,
      };
    case CVType.FETCH_CV_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CVType.UPDATE_CV:
      return {
        ...state,
        loading: true,
      };
    case CVType.UPDATE_CV_SUCCEED:
      return {
        ...state,
        detail: action.payload.detail,
        loading: false,
      };
    default:
      return state;
  }
};
