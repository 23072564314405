import React from "react";
import ReactDOM from "react-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import palette from "@/theme/palette";

interface IProps {
  message: any;
  callback: any;
}

const UseConfirm = (props: IProps) => {
  const {message, callback} = props;
  const container = document.createElement("div");
  const domContainer = document.getElementById("container");
  domContainer && document.body.removeChild(domContainer);
  container.setAttribute("id", "container");
  document.body.appendChild(container);

  const handleClose = (state: any) => () => {
    ReactDOM.unmountComponentAtNode(container);

    callback(state);
  };

  ReactDOM.render(
    <Dialog onClick={handleClose(false)} open={true}>
      <DialogTitle>{"Xác nhận"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <Button onClick={handleClose(false)} style={{color: palette.primary.main}}>
        Ở lại trang
      </Button>
      <Button style={{backgroundColor: palette.primary.main, color: "#fff"}} onClick={handleClose(true)} variant="contained">
        Rời khỏi trang
      </Button>
    </Dialog>,
    container
  );
};

export default UseConfirm;
