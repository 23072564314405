import JobKeywordType from "@/store/redux/types/JobKeywordType";

export const fetchJobKeyword = (payload: any) => ({
  type: JobKeywordType.FETCH_JOB_KEYWORD,
  payload
});

export const fetchJobKeywordSucceed = (payload: any) => ({
  type: JobKeywordType.FETCH_JOB_KEYWORD_SUCCEED,
  payload
});

export const fetchJobKeywordFailed = () => ({
  type: JobKeywordType.FETCH_JOB_KEYWORD_FAILED,
});
