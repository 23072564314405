import ListJobPostType, {IListJobPostState} from "@/store/redux/types/ListJobPostType";

const defaultState: IListJobPostState = {
  job_post: [],
  loading: false
};

export const ListJobPostReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case ListJobPostType.FETCH_LIST_JOB_POST:
      return {
        ...state,
        loading: true,
      };
    case ListJobPostType.FETCH_LIST_JOB_POST_SUCCEED:
      return {
        ...state,
        job_post: action.payload.job_post,
        length: action.payload.length,
        loading: false
      };
    case ListJobPostType.FETCH_LIST_JOB_POST_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
