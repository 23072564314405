import ListCompanyType from "@/store/redux/types/ListCompanyType";
import {all, fork, put, takeLatest, delay} from "redux-saga/effects";
import {apolloClient} from "@/utils/apolloClient";
import {fetchListCompanyFailed, fetchListCompanySucceed} from "@/store/redux/actions/ListCompanyAction";
import {PAGINATE_COMPANY} from "@/graphql/company/query";

function* fetchListCompanySaga(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({query: PAGINATE_COMPANY, variables: action.payload.variables});
    yield put(fetchListCompanySucceed({company: r.data.companys.edges, length: r.data.companys.pageInfo.length}))
  } catch (e) {
    yield put(fetchListCompanyFailed())
  }
}

function* watchFetchListCompanySaga() {
  yield takeLatest(ListCompanyType.FETCH_LIST_COMPANY, fetchListCompanySaga)
}

function* listCompanySaga() {
  yield all([fork(watchFetchListCompanySaga)]);
}

export default listCompanySaga;
