enum ApplyListOtherDetailType {
  FETCH_JOB_APPLY_OTHER_DETAIL = "@@/ApplyListOtherDetail/FETCH_JOB_APPLY_OTHER_DETAIL",
  FETCH_JOB_APPLY_OTHER_DETAIL_SUCCESS = "@@/ApplyListOtherDetail/FETCH_JOB_APPLY_OTHER_DETAIL_SUCCESS",
  FETCH_JOB_APPLY_OTHER_DETAIL_FAILURE = "@@/ApplyListOtherDetail/FETCH_JOB_APPLY_OTHER_DETAIL_FAILURE",
  UPDATE_JOB_APPLY_OTHER_DETAIL = "@@/ApplyListOtherDetail/UPDATE_JOB_APPLY_OTHER_DETAIL",
  UPDATE_JOB_APPLY_OTHER_DETAIL_SUCCESS = "@@/ApplyListOtherDetail/UPDATE_JOB_APPLY_OTHER_DETAIL_SUCCESS",
  UPDATE_JOB_APPLY_OTHER_DETAIL_FAILURE = "@@/ApplyListOtherDetail/UPDATE_JOB_APPLY_OTHER_DETAIL_FAILURE",
  SEND_EMAIL = "@@/ApplyListOtherDetail/SEND_EMAIL",
  SEND_EMAIL_SUCCESS = "@@/ApplyListOtherDetail/SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE = "@@/ApplyListOtherDetail/SEND_EMAIL_FAILURE",
}

interface ApplyListOtherDetailState {
  loading: boolean;
  job_detail: any;
}

export default ApplyListOtherDetailType;
export { ApplyListOtherDetailState };
