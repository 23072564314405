import ApolloClient, { InMemoryCache } from "apollo-boost";

export const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPH_API,
  credentials: "include",
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export const ndmApolloClient = new ApolloClient({
  uri: "https://graph.nhadatmoi.net/graphql",
  credentials: "include",
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
