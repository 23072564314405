import JobLevelType, {IJobLevelState} from "@/store/redux/types/JobLevelType";

const defaultState: IJobLevelState = {
  job_level: [],
  loading: false
};

export const JobLevelReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobLevelType.FETCH_JOB_LEVEL:
      return {
        ...state,
        loading: true,
      };
    case JobLevelType.FETCH_JOB_LEVEL_SUCCEED:
      return {
        ...state,
        job_level: action.payload.job_level,
        loading: false
      };
    case JobLevelType.FETCH_JOB_LEVEL_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
