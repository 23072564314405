import CandidateType from "@/store/redux/types/CandidateType";

export const fetchCandidate = (payload: any) => ({
    type: CandidateType.FETCH_CANDIDATE,
  payload
});

export const fetchCandidateSucceed = (payload: any) => ({
  type: CandidateType.FETCH_CANDIDATE_SUCCEED,
  payload
});

export const fetchCandidateFailed = () => ({
  type: CandidateType.FETCH_CANDIDATE_FAILED,
});


export const createCandidate = (payload: any) => ({
  type: CandidateType.CREATE_CANDIDATE,
  payload
});

export const createCandidateSucceed = (payload: any) => ({
  type: CandidateType.CREATE_CANDIDATE_SUCCEED,
  payload
});

export const createCandidateFailed = () => ({
  type: CandidateType.CREATE_CANDIDATE_FAILED,
});


export const updateCandidate = (payload: any) => ({
  type: CandidateType.UPDATE_CANDIDATE,
  payload
});

export const updateCandidateSucceed = (payload: any) => ({
  type: CandidateType.UPDATE_CANDIDATE_SUCCEED,
  payload
});

export const updateCandidateFailed = () => ({
  type: CandidateType.UPDATE_CANDIDATE_FAILED,
});
