import { combineReducers } from "redux";
import { GlobalToastReducer } from "@/store/redux/reducers/GlobalToastReducer";
import { GlobalModalReducer } from "@/store/redux/reducers/GlobalModalReducer";
import { CompanyReducer } from "@/store/redux/reducers/CompanyReducer";
import { ApplyListReducer } from "@/store/redux/reducers/ApplyListReducer";
import { ApplyListDetailReducer } from "@/store/redux/reducers/ApplyListDetailReducer";
import { ApplyListOtherReducer } from "@/store/redux/reducers/ApplyListOtherReducer";
import { ApplyListOtherDetailReducer } from "@/store/redux/reducers/ApplyListOtherDetailReducer";
import { CoordinateReducer } from "@/store/redux/reducers/CoordinateReducer";
import { JobCategoryReducer } from "@/store/redux/reducers/JobCategoryReducer";
import { JobTitleReducer } from "@/store/redux/reducers/JobTitleReducer";
import { AddressReducer } from "@/store/redux/reducers/AddressReducer";
import { ListCompanyReducer } from "@/store/redux/reducers/ListCompanyReducer";
import { NotificationReducer } from "@/store/redux/reducers/NotificationReducer";
import { JobTypeReducer } from "@/store/redux/reducers/JobTypeReducer";
import { JobLevelReducer } from "@/store/redux/reducers/JobLevelReducer";
import { JobPostReducer } from "@/store/redux/reducers/JobPostReducer";
import { ListJobPostReducer } from "@/store/redux/reducers/ListJobPostReducer";
import { CandidateReducer } from "@/store/redux/reducers/CandidateReducer";
import { ListCandidateReducer } from "@/store/redux/reducers/ListCandidateReducer";
import { LoadingAppReducer } from "@/store/redux/reducers/LoadingAppReducer";
import { UserReducer } from "@/store/redux/reducers/UserReducer";
import { CVReducer } from "@/store/redux/reducers/CVReducer";
import { JobKeywordReducer } from "@/store/redux/reducers/JobKeywordReducer";

const rootReducer = combineReducers({
  listCompany: ListCompanyReducer,
  company: CompanyReducer,
  applyList: ApplyListReducer,
  applyDetail: ApplyListDetailReducer,
  applyListOther: ApplyListOtherReducer,
  applyOtherDetail: ApplyListOtherDetailReducer,
  globalToast: GlobalToastReducer,
  globalModal: GlobalModalReducer,
  coordinate: CoordinateReducer,
  jobCategory: JobCategoryReducer,
  jobTitle: JobTitleReducer,
  jobType: JobTypeReducer,
  jobLevel: JobLevelReducer,
  address: AddressReducer,
  notification: NotificationReducer,
  jobPost: JobPostReducer,
  listJobPost: ListJobPostReducer,
  candidate: CandidateReducer,
  listCandidate: ListCandidateReducer,
  loadingApp: LoadingAppReducer,
  user: UserReducer,
  CV: CVReducer,
  jobKeyword: JobKeywordReducer,
});

export default rootReducer;
