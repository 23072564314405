import JobPostType from "@/store/redux/types/JobPostType";

export const createJobPost = (payload: any) => ({
  type: JobPostType.CREATE_JOB_POST,
  payload,
});

export const createJobPostSucceed = () => ({
  type: JobPostType.CREATE_JOB_POST_SUCCEED,
});

export const createJobPostFailed = (payload: any) => ({
  type: JobPostType.CREATE_JOB_POST_FAILED,
  payload,
});

export const fetchJobPost = (payload: any) => ({
  type: JobPostType.FETCH_JOB_POST,
  payload,
});

export const fetchJobPostSucceed = (payload: any) => ({
  type: JobPostType.FETCH_JOB_POST_SUCCEED,
  payload,
});

export const fetchJobPostFailed = () => ({
  type: JobPostType.FETCH_JOB_POST_FAILED
});


export const updateJobPost = (payload: any) => ({
  type: JobPostType.UPDATE_JOB_POST,
  payload,
});

export const updateJobPostSucceed = (payload: any) => ({
  type: JobPostType.UPDATE_JOB_POST_SUCCEED,
  payload,
});
export const updateJobPostFailed = () => ({
  type: JobPostType.UPDATE_JOB_POST_FAILED,
});


