import UserType from "@/store/redux/types/UserType";
import { takeLatest, put, all, fork } from "redux-saga/effects";
import { axios } from "@/utils/axiosInstance";
import { loadingAppSuccess } from "@/store/redux/actions/LoadingAppAction";
import { fetchUserSuccess } from "@/store/redux/actions/UserAction";

function* fetchUser(action: any) {
  const user = yield axios({
    url: `${process.env.REACT_APP_API_URL}/auth/login`,
    method: "post",
  });
  yield put(fetchUserSuccess(user.data.user));
  yield put(loadingAppSuccess());
}

function* watchFetchUser() {
  yield takeLatest(UserType.FETCH_USER, fetchUser);
}

function* UserSaga() {
  yield all([fork(watchFetchUser)]);
}

export default UserSaga;
