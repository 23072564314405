import gql from "graphql-tag";

export const PAGINATE_JOB_POST = gql`
  query jobPosts($page: Int!, $limit: Int!, $filter: String!) {
    jobPosts(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
          title
          slug
          image
          photos
          job_level {
            _id
            title
          }
          job_type {
            _id
            title
          }
          image
          job_category {
            _id
            title
          }
          number
          description
          requirement
          salary {
            min
            max
            show
          }
          company {
            name
            ref {
              _id
            }
          }
          user {
            _id
            first_name
            last_name
          }
          status
          view_count
          end_date
          created_at
          updated_at
        }
      }
      pageInfo {
        length
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;



export const SINGLE_JOB_POST_BY_ID = gql`
  query jobPost($_id: String!) {
    jobPost(_id: $_id) {
      _id
      title
      job_type {
        _id
        title
      }
      contact {
        name
        email
        phone
      }
      job_level {
        _id
        title
      }
      job_category {
        _id
        title
      }
      number
      description
      requirement
      address {
        city {
          _id
          name
        }
        district {
          _id
          name
        }
        ward {
          _id
          name
        }
        text
        lat
        lng
      }
      salary {
        min
        max
        show
      }
      company {
        ref {
          _id
        }
        name
      }
      image
      photos
      video
      benefit {
        benefit_id {
          _id
        }
        content
      }
      end_date
      view_count
      created_at
      updated_at
    }
  }
`;

export const SINGLE_JOB_POST_BY_SLUG = gql`
  query jobPost($slug: String!) {
    jobPost(slug: $slug) {
      _id
      title
      job_type {
        _id
        title
      }
      job_level {
        _id
        title
      }
      job_category {
        _id
        title
      }
      description
      requirement
      address {
        city
        district
        ward
        text
        lat
        lng
      }
      salary {
        min
        max
        show
      }
      company {
        ref
        name
      }
      view_count
      created_at
      updated_at
    }
  }
`;
export const JOB_POST_COUNT = gql`
  query jobPosts($page: Int!, $limit: Int!, $filter: String!) {
    jobPosts(page: $page, filter: $filter, limit: $limit) {
      edges {
        node {
          _id
        }
      }
      pageInfo {
        length
      }
    }
  }
`;
