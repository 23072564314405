import ApplyListOtherType from "@/store/redux/types/ApplyListOtherType";

import { takeLatest, put, all, fork } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import { PAGINATE_JOB_APPLY_OTHER } from "@/graphql/job_apply_other/query";
import { fetchJobApplyOtherSuccess, setPage } from "@/store/redux/actions/ApplyListOtherAction";

function* fetchJobApplyOtherSaga(action: any) {
  try {
    yield put(setPage(action.payload.page));
    const r = yield apolloClient.query({ query: PAGINATE_JOB_APPLY_OTHER, variables: action.payload });
    yield put(fetchJobApplyOtherSuccess(r.data.jobApplyOthers));
  } catch (err) {
    console.log(err);
  }
}

function* watchApplyListOtherSaga() {
  yield takeLatest(ApplyListOtherType.FETCH_JOB_APPLY_OTHER, fetchJobApplyOtherSaga);
}

function* ApplyListOtherSaga() {
  yield all([fork(watchApplyListOtherSaga)]);
}

export default ApplyListOtherSaga;
