import JobLevelType from "@/store/redux/types/JobLevelType";
import {takeLatest, put, fork, all, delay} from "redux-saga/effects";
import {apolloClient} from "@/utils/apolloClient";
import {fetchJobLevelFailed, fetchJobLevelSucceed} from "@/store/redux/actions/JobLevelAction";
import {PAGINATE_JOB_LEVEL} from "@/graphql/job_level/query";

function* fetchJobLevel(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({query: PAGINATE_JOB_LEVEL, variables: action.payload.variables});
    yield put(fetchJobLevelSucceed({
      job_level: r.data.jobLevels.edges,
    }))
  } catch (e) {
    yield put(fetchJobLevelFailed())
  }
}

function* watchFetchJobLevel() {
  yield takeLatest(JobLevelType.FETCH_JOB_LEVEL, fetchJobLevel)
}

function* jobLevelSaga() {
  yield all([
    fork(watchFetchJobLevel),
  ])
}

export default jobLevelSaga;
