import CandidateType, {ICandidateState} from "@/store/redux/types/CandidateType";

const defaultState: ICandidateState = {
  detail: {
    _id: "",
    first_name: "",
    last_name: "",
    birthday: "",
    address: "",
    email: "",
    phone: "",
    current_job: "",
    expect_salary: 0,
    interest: [],
    user: "",
    avatar: "",
    job_open: false,
    cv: "",
    photos: [],
    files: {
      name: "",
      url: "",
    },
    exp: [],
    study: [],
    project: [],
    note: [],
    upload_by: {},
    created_at: "",
    updated_at: "",
  },
  same_cv: [],
  loading: false,
};
export const CandidateReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case CandidateType.FETCH_CANDIDATE:
      return {
        ...state,
        loading: true,
      };
    case CandidateType.FETCH_CANDIDATE_SUCCEED:
      return {
        ...state,
        loading: false,
        detail: action.payload.detail,
        same_cv: action.payload.same_cv
      };
    case CandidateType.FETCH_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CandidateType.CREATE_CANDIDATE:
      return {
        ...state,
        loading: true,
      };
    case CandidateType.CREATE_CANDIDATE_SUCCEED:
      return {
        ...state,
        loading: false,
        detail: action.payload.detail,
      };
    case CandidateType.CREATE_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CandidateType.UPDATE_CANDIDATE:
      return {
        ...state,
        loading: true,
      };
    case CandidateType.UPDATE_CANDIDATE_SUCCEED:
      return {
        ...state,
        loading: false,
        detail: action.payload.detail
      };
    case CandidateType.UPDATE_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
