import NotificationType, {INotificationState} from "@/store/redux/types/NotificationType";

const defaultState: INotificationState = {
  notification: [],
  length: 0,
  unread_length: 0,
  loading: false
};

export const NotificationReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case NotificationType.FETCH_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case NotificationType.FETCH_NOTIFICATION_SUCCEED:
      return {
        ...state,
        notification: action.payload.notification,
        length: action.payload.length,
        loading: false
      };
    case NotificationType.FETCH_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false
      };

    case NotificationType.FETCH_UNREAD_NOTIFICATION_LENGTH:
      return {
        ...state,
        loading: true,
      };
    case NotificationType.FETCH_UNREAD_NOTIFICATION_LENGTH_SUCCEED:
      return {
        ...state,
        unread_length: action.payload.unread_length,
        loading: false
      };
    case NotificationType.FETCH_UNREAD_NOTIFICATION_LENGTH_FAILED:
      return {
        ...state,
        loading: false
      };

    case NotificationType.UPDATE_READ_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case NotificationType.UPDATE_READ_NOTIFICATION_SUCCEED:
      return {
        ...state,
        loading: false
      };
    case NotificationType.UPDATE_READ_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false
      };

    case NotificationType.UPDATE_READ_ALL_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case NotificationType.UPDATE_READ_ALL_NOTIFICATION_SUCCEED:
      return {
        ...state,
        loading: false
      };
    case NotificationType.UPDATE_READ_ALL_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
