import gql from "graphql-tag";

export const PAGINATE_DISTRICT = gql`
    query paginateDistrict($page: Int!, $limit: Int!, $filter: String!) {
        districts(page: $page, filter: $filter, limit: $limit) {
            edges {
                node {
                    _id
                    name
                }
            }
        }
    }
`;

export const PAGINATE_ADDRESS_DISTRICT = gql`
    query paginateDistrict($page: Int!, $limit: Int!, $filter: String!) {
        districts(page: $page, filter: $filter, limit: $limit) {
            edges {
                node {
                    _id
                    name
                    city {
                        name
                    }
                }
            }
            pageInfo {
                length
            }
        }
    }
`;

export const SINGLE_DISTRICT = gql`
    query district($_id: String!) {
        district(_id: $_id) {
            _id
            name
            title
            description
            slug
            focus_keyword
            seo_title
            seo_description
            image
            image_description
        }
    }
`;
