import AddressType from "@/store/redux/types/AddressType";
import { all, fork, put, takeLatest } from "redux-saga/effects";
import {
  fetchCityFailed,
  fetchCitySucceed,
  fetchDistrictFailed,
  fetchDistrictSucceed,
  fetchWardFailed,
  fetchWardSucceed,
} from "@/store/redux/actions/AddressAction";
import { apolloClient } from "@/utils/apolloClient";
import { PAGINATE_CITY } from "@/graphql/city/query";
import { PAGINATE_DISTRICT } from "@/graphql/district/query";
import { PAGINATE_WARD } from "@/graphql/ward/query";

function* fetchCitySaga(action: any) {
  try {
    let r = yield apolloClient.query({ query: PAGINATE_CITY, variables: action.payload.variables });
    yield put(fetchCitySucceed(r.data.citys));
  } catch (e) {
    yield put(fetchCityFailed());
  }
}

function* fetchDistrictSaga(action: any) {
  try {
    let r = yield apolloClient.query({ query: PAGINATE_DISTRICT, variables: action.payload.variables });
    yield put(fetchDistrictSucceed(r.data.districts));
  } catch (e) {
    yield put(fetchDistrictFailed());
  }
}

function* fetchWardSaga(action: any) {
  try {
    let r = yield apolloClient.query({ query: PAGINATE_WARD, variables: action.payload.variables });
    yield put(fetchWardSucceed(r.data.wards));
  } catch (e) {
    yield put(fetchWardFailed());
  }
}

function* watchAddressSaga() {
  yield takeLatest(AddressType.FETCH_CITY, fetchCitySaga);
  yield takeLatest(AddressType.FETCH_DISTRICT, fetchDistrictSaga);
  yield takeLatest(AddressType.FETCH_WARD, fetchWardSaga);
}

function* addressSaga() {
  yield all([fork(watchAddressSaga)]);
}

export default addressSaga;
