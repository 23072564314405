import CandidateType from "@/store/redux/types/CandidateType";
import { all, delay, fork, put, takeLatest } from "redux-saga/effects";
import { apolloClient } from "@/utils/apolloClient";
import {
  createCandidateFailed,
  createCandidateSucceed,
  fetchCandidateFailed,
  fetchCandidateSucceed,
  updateCandidateFailed,
} from "@/store/redux/actions/CandidateAction";
import { CANDIDATE_DETAIL, PAGINATE_CANDIDATE } from "@/graphql/candidate/query";
import { CREATE_CANDIDATE, UPDATE_CANDIDATE } from "@/graphql/candidate/mutation";
import { closeModal } from "@/store/redux/actions/GlobalModalAction";
import { enqueueSnackbar } from "@/store/redux/actions/GlobalToastAction";
import { graphqlFilter } from "@/helpers/string";

function* fetchCandidate(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({ query: CANDIDATE_DETAIL, variables: action.payload.variables });
    let same_cv: any = [];
    if (r.data.candidate.email) {
      let variables = {
        page: 1,
        limit: 4,
        filter: graphqlFilter({ except: r.data.candidate._id, email: r.data.candidate.email, public: true }),
      };
      let sameCV = yield apolloClient.query({ query: PAGINATE_CANDIDATE, variables: variables });
      same_cv = sameCV.data.candidates.edges;
    }
    yield put(
      fetchCandidateSucceed({
        detail: r.data.candidate,
        same_cv: same_cv,
      })
    );
  } catch (e) {
    yield put(fetchCandidateFailed());
  }
}

function* createCandidateSaga(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.mutate({ mutation: CREATE_CANDIDATE, variables: action.payload.variables });
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(
      createCandidateSucceed({
        detail: r.data.candidateCreate,
      })
    );
    yield (window.location.href = `/cv/list`);
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(createCandidateFailed());
  }
}

function* updateCandidateSaga(action: any) {
  try {
    yield delay(300);
    yield apolloClient.mutate({ mutation: UPDATE_CANDIDATE, variables: action.payload.variables });
    const toast = {
      message: "Cập nhật thành công",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    // yield put(updateCandidateSucceed({
    //   detail: r.data.candidateUpdate,
    // }))
    yield window.location.reload();
  } catch (e) {
    const toast = {
      message: "Lỗi cập nhât máy chủ",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    };
    yield put(closeModal());
    yield put(enqueueSnackbar(toast));
    yield put(updateCandidateFailed());
  }
}

function* watchFetchCandidateSaga() {
  yield takeLatest(CandidateType.FETCH_CANDIDATE, fetchCandidate);
  yield takeLatest(CandidateType.CREATE_CANDIDATE, createCandidateSaga);
  yield takeLatest(CandidateType.UPDATE_CANDIDATE, updateCandidateSaga);
}

function* candidateSaga() {
  yield all([fork(watchFetchCandidateSaga)]);
}

export default candidateSaga;
