import gql from "graphql-tag";

export const JOB_POST_CREATE = gql`
  mutation jobPostCreate($input: JobPostInput!) {
    jobPostCreate(input: $input) {
      _id
      slug
    }
  }
`;

export const JOB_POST_UPDATE = gql`
  mutation jobPostUpdate($input: JobPostInput!) {
    jobPostUpdate(input: $input) {
      _id
      title
      job_type {
        _id
        title
      }
      job_level {
        _id
        title
      }
      job_category {
        _id
        title
      }
      number
      description
      requirement
      address {
        city {
          _id
          name
        }
        district {
          _id
          name
        }
        ward {
          _id
          name
        }
        text
        lat
        lng
      }
      salary {
        min
        max
        show
      }
      company {
        ref {
          _id
        }
        name
      }
      image
      photos
      video
      benefit {
        benefit_id {
          _id
        }
        content
      }
      end_date
      view_count
      created_at
      updated_at
    }
  }
`;

export const CREATE_JOB_POST = gql`
  mutation createJobPost($input: JobPostInput!) {
    jobPostCreate(input: $input) {
      _id
      slug
    }
  }
`;

export const UPDATE_JOB_POST = gql`
  mutation updateJobPost($input: JobPostInput!) {
    jobPostUpdate(input: $input) {
      _id
      title
      job_type {
        _id
        title
      }
      job_level {
        _id
        title
      }
      job_category {
        _id
        title
      }
      number
      description
      requirement
      address {
        city {
          _id
          name
        }
        district {
          _id
          name
        }
        ward {
          _id
          name
        }
        text
        lat
        lng
      }
      salary {
        min
        max
        show
      }
      company {
        ref {
          _id
        }
        name
      }
      image
      photos
      video
      benefit {
        benefit_id {
          _id
        }
        content
      }
      end_date
      view_count
      created_at
      updated_at
    }
  }
`;
