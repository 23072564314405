import JobTitleType from "@/store/redux/types/JobTitleType";
import {takeLatest, put, fork, all, delay} from "redux-saga/effects";
import {apolloClient} from "@/utils/apolloClient";
import {fetchJobTitleFailed, fetchJobTitleSucceed} from "@/store/redux/actions/JobTitleAction";
import {PAGINATE_JOB_TITLE} from "@/graphql/job_title/query";

function* fetchJobTitle(action: any) {
  try {
    yield delay(300);
    let r = yield apolloClient.query({query: PAGINATE_JOB_TITLE, variables: action.payload.variables});
    yield put(fetchJobTitleSucceed({
      job_title: r.data.jobTitles.edges,
      hasNextPage: r.data.jobTitles.pageInfo.hasNextPage,
    }))
  } catch (e) {
    yield put(fetchJobTitleFailed())
  }
}

function* watchFetchJobTitle() {
  yield takeLatest(JobTitleType.FETCH_JOB_TITLE, fetchJobTitle)
}

function* jobTitleSaga() {
  yield all([
    fork(watchFetchJobTitle),
  ])
}

export default jobTitleSaga;
