import JobPostType, {IJobPostState} from "@/store/redux/types/JobPostType";

const defaultState: IJobPostState = {
  detail: {},
  loading: false,
};
export const JobPostReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case JobPostType.CREATE_JOB_POST:
      return {
        ...state,
        loading: true,
      };
    case JobPostType.CREATE_JOB_POST_SUCCEED:
      return {
        ...state,
        loading: false,
      };
    case JobPostType.CREATE_JOB_POST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case JobPostType.FETCH_JOB_POST:
      return {
        ...state,
        loading: true,
      };
    case JobPostType.FETCH_JOB_POST_SUCCEED:
      return {
        ...state,
        loading: false,
        detail: action.payload.detail,
      };
    case JobPostType.FETCH_JOB_POST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case JobPostType.UPDATE_JOB_POST:
      return {
        ...state,
        loading: true,
      };
    case JobPostType.UPDATE_JOB_POST_SUCCEED:
      return {
        ...state,
        detail: action.payload.detail,
        loading: false,
      };
    default:
      return state;
  }
};
