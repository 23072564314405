import CompanyType, {ICompanyState} from "@/store/redux/types/CompanyType";

const defaultState: ICompanyState = {
  detail: {
    _id: "",
    name: "",
    business_code: "",
    slug: "",
    verify_status: false,
    logo: "",
    cover: "",
    website: "",
    phone: "",
    follow: "",
    facebook: "",
    youtube: "",
    description: "",
    slogan: "",
    album: [],
    benefit: [],
    story: [],
    job_category: {
      _id: "",
      title: "",
      slug: "",
    },
    created_by: {
      first_name: "",
      last_name: "",
      avatar: "",
    },
    office: [],
    people: [],
  },
  loading: false,
};
export const CompanyReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case CompanyType.CREATE_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case CompanyType.CREATE_COMPANY_SUCCEED:
      return {
        ...state,
        loading: false,
      };
    case CompanyType.CREATE_COMPANY_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CompanyType.FETCH_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case CompanyType.FETCH_COMPANY_SUCCEED:
      return {
        ...state,
        loading: false,
        detail: action.payload.detail,
      };
    case CompanyType.FETCH_COMPANY_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CompanyType.UPDATE_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case CompanyType.UPDATE_COMPANY_SUCCEED:
      return {
        ...state,
        detail: action.payload.detail,
        loading: false,
      };
    default:
      return state;
  }
};
