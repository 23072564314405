import ApplyListOtherDetailType, { ApplyListOtherDetailState } from "@/store/redux/types/ApplyListOtherDetailType";
const defaultState: ApplyListOtherDetailState = {
  job_detail: {},
  loading: false,
};
export const ApplyListOtherDetailReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case ApplyListOtherDetailType.FETCH_JOB_APPLY_OTHER_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case ApplyListOtherDetailType.FETCH_JOB_APPLY_OTHER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        job_detail: action.payload,
      };
    case ApplyListOtherDetailType.UPDATE_JOB_APPLY_OTHER_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case ApplyListOtherDetailType.UPDATE_JOB_APPLY_OTHER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        job_detail: action.payload,
      };
    default:
      return state;
  }
};
