import CompanyType from "@/store/redux/types/CompanyType";

export const createCompany = (payload: any) => ({
  type: CompanyType.CREATE_COMPANY,
  payload,
});

export const createCompanySucceed = () => ({
  type: CompanyType.CREATE_COMPANY_SUCCEED,
});

export const createCompanyFailed = (payload: any) => ({
  type: CompanyType.CREATE_COMPANY_FAILED,
  payload,
});

export const fetchCompany = (payload: any) => ({
  type: CompanyType.FETCH_COMPANY,
  payload,
});

export const fetchCompanySucceed = (payload: any) => ({
  type: CompanyType.FETCH_COMPANY_SUCCEED,
  payload,
});

export const fetchCompanyFailed = () => ({
  type: CompanyType.FETCH_COMPANY_FAILED
});


export const updateCompany = (payload: any) => ({
  type: CompanyType.UPDATE_COMPANY,
  payload,
});

export const updateCompanySucceed = (payload: any) => ({
  type: CompanyType.UPDATE_COMPANY_SUCCEED,
  payload,
});
export const updateCompanyFailed = () => ({
  type: CompanyType.UPDATE_COMPANY_FAILED,
});


