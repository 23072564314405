import gql from "graphql-tag";

export const PAGINATE_CANDIDATE = gql`
  query candidate($page: Int!, $limit: Int!, $filter: String!) {
    candidates(page: $page, filter: $filter, limit: $limit) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        length
      }
      edges {
        node {
          _id
          first_name
          last_name
          interest
          user {
            _id
          }
          job_open
          avatar
          cv
          photos
          files {
            name
            url
          }

          birthday
          gender
          phone
          email
          address
          website

          upload_by {
            _id
            first_name
            last_name
          }
          public
          created_at
          updated_at
        }
      }
    }
  }
`;

export const CANDIDATE_DETAIL = gql`
  query candidateDetail($_id: String!) {
    candidate(_id: $_id) {
      _id
      first_name
      last_name
      interest
      user {
        _id
      }
      avatar
      job_open
      cv
      photos
      files {
        name
        url
      }

      birthday
      gender
      phone
      email
      address
      website
      target
      study {
        timeline
        position
        description
      }
      exp {
        timeline
        position
        description
      }
      project {
        name
        url
        member
        position
        description
      }
      note
      upload_by {
        _id
        first_name
        last_name
      }

      study {
        timeline
        position
        description
      }
      exp {
        timeline
        position
        description
      }
      project {
        name
        url
        member
        position
        description
      }
      public
      target
      created_at
      updated_at
    }
  }
`;
